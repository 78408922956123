import { CellProps } from "react-table";
import { mapConstantsToTranslations } from "../../lib/fields";
import TableTextCell from "./TableTextCell";

const TableConstantsCell = <D extends object = Record<string, unknown>>({ value, ...textCellProps }: CellProps<D>) => {
    const { id, translate } = textCellProps.column;
    const wrappedValue = !Array.isArray(value) ? [value] : value;
    const mappedValues = wrappedValue.map((v) => mapConstantsToTranslations(id, v));
    const translatedValues = mappedValues.map((cur) => (cur != null && typeof translate === "function" ? translate(cur) : cur)).join(", ");
    return <TableTextCell value={translatedValues} {...textCellProps} />;
};

export default TableConstantsCell;
